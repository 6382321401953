@import '../../placeholders';
@import '../../vars';

.team-wrapper {
  .team-members {
    .member:first-of-type {
      margin-bottom: $base-spacing-large;
    }
  }
}
