@import-normalize;
@import './vars';
@import './placeholders';

// Theme

:root {
  --color-bg-1: #fefefe;

  --theme-color-1: #0d1a6c;
  --theme-color-2: #dcab13;
  --theme-color-3: #0971c3;
  --theme-color-4: #fff;
  --theme-color-5: rgba(9, 113, 195, 0.7);
  --theme-color-6: #f3f3f3;

  --tooltip-color: #fff;
  --tooltip-background: #0971c3;

  --menu-opener-bg: #0971c3;
  --menu-opener-icon: #fff;

  --menu-overlay-bg: rgba(30, 30, 30, 0.4);

  --swiper-bullet-active: #0971c3;
  --swiper-bullet-inactive: rgba(255, 255, 255, 1);

  --action-link-color-1: #fff;
  --action-link-color-2: #dcab13;

  --input-text-color: #333;
  --color-error: #dd3444;

  --snackbar-background: rgb(9, 113, 195, 0.9);
  --snackbar-close-background: #0d1a6c;
  --snackbar-close-fill: #fff;
  --snackbar-close-text: #fff;

  --hero-bg-color: rgba(13, 26, 108, 0.85);
  --hero-text: #fff;

  --shadow-color: rgba(33, 33, 33, 0.4);
}

// Global Style

body {
  margin: 0;
  font-family: $font-family-1;
  font-size: $font-size-6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &,
  & *,
  & * * {
    box-sizing: border-box;
  }
}

.container {
  min-height: calc(100vh - #{$header-height + $footer-height});
  min-height: calc(var(--window-height) - #{$header-height + $footer-height});
}

.content-wrapper {
  $vpadding: 24px;
  padding: $vpadding $side-padding-desktop;

  @media #{$breakpoint-mobile} {
    padding: $vpadding $side-padding-mobile;
  }
}

.content-wrapper-no-vp {
  $vpadding: 0px;
  padding: $vpadding $side-padding-desktop;

  @media #{$breakpoint-mobile} {
    padding: $vpadding $side-padding-mobile;
  }
}

.custom-snackbar {
  &-close {
    $size: 32px;

    @extend %df-aic-jcc;

    background: var(--snackbar-close-background);
    border: none;
    border-radius: 100%;
    height: $size;
    width: $size;

    &-icon {
      fill: var(--snackbar-close-fill);
    }
  }

  &-message {
    font-family: $font-family-1;
    font-size: $font-size-1;
    color: var(--snackbar-close-text);
  }

  &-item {
    background-color: var(--snackbar-background) !important;

    #notistack-snackbar {
      @extend %df-aic-jsb;
      width: 100%;
    }
  }
}

.tagset {
  @extend %df-aic-jcfs-fww;

  .tag {
    $spacing: 6px;

    margin-right: $spacing;
    margin-bottom: $spacing;
  }
}

.tooltip-class {
  background-color: var(--tooltip-background);
  color: var(--tooltip-color);
  max-width: 340px;
  line-height: 1.5em;
  font-size: $font-size-4;

  &-arrow {
    background-color: var(--tooltip-background);
  }
}

.no-scroll {
  overflow: hidden;
}
