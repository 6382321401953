// General

$font-family-1: 'Open Sans', Helvetica, Arial, Lucida, sans-serif;
$font-family-2: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;

$side-padding-desktop: 12vw;
$side-padding-mobile: 4vw;

$base-spacing-x-small: 4px;
$base-spacing-small: 8px;
$base-spacing-medium: 12px;
$base-spacing-large: 24px;

$font-size-1: 14px;
$font-size-2: 24px;
$font-size-3: 18px;
$font-size-4: 12px;
$font-size-5: 48px;
$font-size-6: 16px;
$font-size-7: 32px;

$breakpoint-mobile: '(max-width: 768px)';
$breakpoint-phone: '(max-width: 576px)';

$input-heigth: 48px;
$textarea-height: 124px;

// Components

$header-height: 140px;
$header-height-mobile: 80px;
$footer-height: 140px;
