@import '../../placeholders';
@import '../../vars';

.home {
  .slide-hero {
    .hero-title {
      text-align: center;
      line-height: 1.5em;
      font-size: $font-size-2;
      padding: 0 24px;
    }
  }
}

.featured-services {
  display: flex;
  align-items: center;
  flex-direction: column;

  .featured-item {
    max-width: 800px;
    margin-bottom: $base-spacing-large * 1.5;
  }
}

.solution {
  background: var(--theme-color-6);

  &-items {
    &-wrapper {
      @extend %df-ais-jcsa-fww;

      .solution-item {
        background: var(--theme-color-4);
      }
    }

    &-undertitle {
      text-align: center;
      font-size: $font-size-3;

      a {
        text-decoration: none;
        color: var(--theme-color-3);
        font-weight: 600;
      }
    }
  }
}
