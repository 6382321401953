@import '../../vars';
@import '../../placeholders';

.header {
  $logo-width: $header-height - 16px;
  $color-navigation-item: #fff;
  $color-active-navigation-item: var(--theme-color-2);

  @extend %df-aic-jsb;

  height: $header-height;
  background: var(--theme-color-1);

  background-image: url('../../assets/img/wave-down.png');
  background-size: 100% 170px;
  background-repeat: no-repeat;
  background-position: top;
  padding: 0 $side-padding-desktop;
  font-family: $font-family-2;

  @media #{$breakpoint-mobile} {
    padding: 0 $side-padding-mobile;
    height: $header-height-mobile;
    background-size: 100% $header-height - 30px;

    .header-logo,
    .header-logo-image {
      $size: $header-height-mobile - 16px;

      width: $size;
      height: $size;
    }
  }

  &-logo {
    width: $logo-width;

    &-image {
      $size: $header-height - 20px;

      height: $size;
      width: $size;
    }
  }

  &-navigation {
    @extend %df-aic-jsb;

    padding-left: $base-spacing-small;

    &-item {
      @extend %df-aic-jcc;

      .divider {
        color: var(--theme-color-4);
        padding-left: $base-spacing-medium;
      }

      &.is-desktop {
        padding-top: 60px;
      }
    }

    &-link {
      color: $color-navigation-item;
      font-weight: 600;
      font-size: $font-size-3;
      text-decoration: none;
      margin-left: $base-spacing-medium;

      &:visited {
        color: $color-navigation-item;
      }

      &.active,
      &:hover {
        color: $color-active-navigation-item;
      }
    }
  }
}

.active {
  font-weight: bold;
}
