@import '../../placeholders';
@import '../../vars';

.hero {
  @extend %bsc-brnr-bpc;
  @extend %df-aic-jcfs-fww;

  position: relative;
  box-shadow: inset 0 0 0 1000px var(--hero-bg-color);

  &-title {
    margin: 0;
    color: var(--hero-text);
    font-family: $font-family-2;
    font-size: $font-size-5;
    font-weight: 600;
  }

  &-subtitle {
    color: var(--hero-text);
    font-family: $font-family-2;
    font-size: $font-size-3;
    line-height: 2em;
  }
}
