@import '../../placeholders';
@import '../../vars';

@keyframes rotate {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

.about {
  &-us {
    $logo-size: 260px;

    @extend %df-aic-jsb;

    .logo {
      width: $logo-size;
      height: $logo-size;
      animation: rotate 3s linear 0s infinite;

      @media #{$breakpoint-mobile} {
        $logo-size-mobile: 200px;

        width: $logo-size-mobile;
        height: $logo-size-mobile;
        animation: none;
      }
    }

    &-content {
      width: calc(100% - #{$logo-size});
      padding-right: $base-spacing-large;

      &-paragraph {
        line-height: 1.5em;
      }
    }
  }

  &-mission {
    background: var(--theme-color-6);

    &-content {
      line-height: 1.5em;
    }
  }

  &-vision {
    &-content {
      line-height: 1.5em;
    }
  }

  &-values {
    background: var(--theme-color-6);

    &-text {
      line-height: 1.5em;
    }

    &-content {
      line-height: 1.5em;
    }
  }

  @media #{$breakpoint-mobile} {
    .about-us {
      flex-direction: column-reverse;

      &-content {
        width: 100%;
        padding-top: $base-spacing-medium;
        padding-right: 0;
      }
    }
  }
}
