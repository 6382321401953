@import '../../vars';
@import '../../placeholders';

.footer {
  @extend %df-aic-jsb;

  background-color: var(--theme-color-1);
  background-image: url('../../assets/img/wave.png');
  background-size: 100% $footer-height;
  background-position: center;
  background-repeat: no-repeat;
  padding: 12px $side-padding-desktop;
  min-height: $footer-height;

  @media #{$breakpoint-mobile} {
    padding: 12px $side-padding-mobile;
  }

  @media #{$breakpoint-phone} {
    flex-direction: column;
    background-position: bottom;

    .footer-contacts {
      align-items: center;
    }
  }

  &-contacts {
    @extend %df-fdc-jcc-aifs;

    .action-link:not(:last-child) {
      margin-bottom: 8px;
    }

    @media #{$breakpoint-phone} {
      margin-top: $base-spacing-large;
      padding-bottom: $base-spacing-large;
    }
  }

  .logo {
    $size: 100px;

    width: $size;
    height: $size;
  }
}
