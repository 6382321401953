@import '../../placeholders';
@import '../../vars';

.menu-mobile {
  &-trigger {
    $size: 48px;

    width: $size;
    height: $size;
    border-radius: 100%;
    border: none;
    background-color: var(--menu-opener-bg);
    overflow: hidden;

    &-ripple {
      border-radius: 100%;
    }

    &-icon {
      fill: var(--menu-opener-icon);
    }
  }

  &-opened {
    $menu-width: 280px;

    position: relative;

    &-bg {
      bottom: 0;
      left: 32px;
      right: -42px;
      top: 70vh;
      position: absolute;

      .logo-menu {
        opacity: 0.2;
        width: $menu-width;
        height: $menu-width - 40px;
      }
    }

    &-container {
      @extend %df-aic-jcc;

      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 10000;
    }

    &-overlay {
      background: var(--menu-overlay-bg);
      height: 100vh;
      height: var(--window-height);
      width: calc(var(--window-width) - #{$menu-width});
    }

    &-content {
      @extend %df-fdc-jcc-aife;

      height: 100vh;
      height: var(--window-height);
      width: $menu-width;
      background: var(--theme-color-1);
      position: relative;

      &-links {
        @extend %df-fdc-jcc-aife;
        margin-bottom: auto;
      }

      .header-navigation-link {
        font-size: $font-size-2;
        margin-bottom: $base-spacing-large * 1.5;
        padding-right: $side-padding-mobile;
      }

      .menu-mobile-opener {
        margin-top: 16px;
        margin-right: $side-padding-mobile;
        margin-bottom: auto;
      }
    }
  }
}
