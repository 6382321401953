%df-aic-jsb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

%df-ais-jsb {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

%df-aic-jcc {
  display: flex;
  align-items: center;
  justify-content: center;
}

%df-fdc-jcc-aife {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

%df-aic-jcc-fdc {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

%df-fdc-jcc-aifs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

%dif-aic-jcfs {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}

%df-aic-jcfs-fww {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

%df-ais-jcsa-fww {
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  flex-wrap: wrap;
}

%bsc-brnr-bpc {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
