@import '../../vars';
@import '../../placeholders';
@import '../../index.scss';

.services-page {
  .header-wrapper {
    padding-bottom: 0;
  }

  &-section {
    @extend .content-wrapper;

    &:nth-child(odd) {
      background: var(--theme-color-6);
    }

    &-description {
      margin-top: 0;
      margin-bottom: $base-spacing-large + 4px;
      line-height: 1.5em;
      text-indent: 24px;
    }
  }

  @media #{$breakpoint-phone} {
    .tag {
      padding: 16px 12px;
    }
  }
}
