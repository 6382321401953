.swiper-pagination-bullet {
  opacity: 0.6;
  background: var(--swiper-bullet-inactive);
  transition: transform 300ms ease-out;

  &-active {
    opacity: 1;
    background: var(--swiper-bullet-active);
  }

  &:hover {
    transform: scale(1.5);
  }
}
